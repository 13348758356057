@import "styles/_variables";

.action-modal {
  height: 100%;
  color: #000000;

  &:focus-visible {
    outline: none;
  }

  .action-modal-content {
    padding: 0 25px 25px;

    .action-modal-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 64px;
      border-bottom: #000000 solid 0.5px;

      .action-modal-title {
        color: #000000;
        font-size: 22px;
        line-height: 64px;
        font-weight: 400;
      }

      .action-modal-error {
        color: #ff0000;
        font-size: 22px;
        line-height: 64px;
        font-weight: 400;
      }
      .action-modal-error-level {
        color: #ff0000;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
      }
    }

    .description {
      margin: 10px 0 !important;
      font-weight: normal !important;
      color: #000000;
    }

    .action-modal-form {
      .modal-input-container {
        display: flex;
        flex-direction: column;

        .custom-select-options {
          max-height: 250px;
        }

        input {
          padding: 5px;
          color: #000000;
          &:focus-visible {
            outline: none;
          }
        }

        textarea {
          padding: 5px;
          color: #000000;
          &:focus-visible {
            outline: none;
          }
        }

        .modal-label-container {
          display: flex;
          justify-content: space-between;
        }
        .modal-label {
          color: #000000;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;
          margin: 17px 0;

          .mandatory {
            color: #FF0000;
            font-weight: 400;
            line-height: 12px;
          }
        }
        .counter {
          color: #000000;
          font-weight: 400;
          line-height: 12px;
          margin: 17px 0;
        }
      }
    }

    .bo-form-chip-list {
      display: flex;
      flex-wrap: wrap;
      .bo-form-chip {
        display: flex;
        align-items: center;
        font-family: var(--elliot-bold);
        color: #0d243f;
        margin-right: 16px;
        margin-bottom: 8px;
        background-color: #dfe3e6;
        padding: 9px;
        border-radius: 16px;
        & .chip-icon {
          cursor: pointer;
          height: 8px;
          margin-left: 12px;
        }
      }
    }
  }
  .footer-button-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;

    .footer-button {
      width: 50%;
      cursor: pointer;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      font-size: 12px;
      letter-spacing: 1px;
      line-height: 10px;
      border: #242242 solid 1px;

      &:disabled {
        background-color: rgba(36, 34, 66, 0.2);
        border: rgba(36, 34, 66, 0.2) solid 1px !important;
      }
    }

    .button {
      &-confirm {
        background-color: #242242;
        color: #ffffff;
      }
      &-delete {
        background-color: #fff;
        color: #242242;
      }
    }
  }

  .custom-select-wrapper {
    height: 50px;
    .custom-select-input {
      height: 40px;
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 4px;
      padding: 10px;

      .label {
        color: #666666;
        font-size: 14px;
      }
    }

    .custom-select-options {
      background-color: #ffffff;
      animation-duration: 500ms;
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      .custom-select-option {
        background-color: #ffffff;
      }
    }
  }
}


@media (min-width: $breakpoint-tablet) {
  .action-modal {
    padding: 0 !important;
    height: fit-content !important;

    .footer-button {
      border: none !important;
      border-top: #242242 solid 1px !important;
    }
  }
}
