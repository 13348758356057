@import "styles/_variables";

.anchor-tabs {
  margin-left: -32px;
  padding-left: 32px;
  width: calc(100% + 64px);
  padding-right: 32px;
  position: relative;

  /* Hide scrollbar for IE, Edge and Firefox */
  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .react-horizontal-scrolling-menu--item {
    height: 42px;
    background-color: transparent;
    border-top: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    width: 227px;
    min-width: 227px;
    &:first-child {
      border-left: 1px solid #ffffff;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      font-size: 12px;
      font-family: var(--elliot-bold);
      text-decoration: none;
      height: 100%;
      width: 100%;
      &:hover,
      &:active,
      &.active {
        background-color: #ffffff;
        color: #0C2340;
      }
    }
  }

  .react-horizontal-scrolling-menu--arrow-left,
  .react-horizontal-scrolling-menu--arrow-right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 32px;
  }
  .react-horizontal-scrolling-menu--arrow-left {
    left: 0;
  }
  .react-horizontal-scrolling-menu--arrow-right {
    right: 0;
  }
  .react-horizontal-scrolling-menu--arrow-left button,
  .react-horizontal-scrolling-menu--arrow-right button {
    width: 100%;
    height: 100%;
    outline: none;
    border: 0;
    background-color: transparent;
    &:disabled {
      display: none;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    margin-left: 0;
    padding-left: 0;
    width: 100%;
    padding-right: 0;
    .react-horizontal-scrolling-menu--item {
      cursor: pointer;
      width: calc(100% / 2);
      min-width: calc(100% / 2);
      height: 58px;
      a {
        font-size: 14px;
      }
    }

    .react-horizontal-scrolling-menu--arrow-left,
    .react-horizontal-scrolling-menu--arrow-right {
      display: none;
    }
  }
}
