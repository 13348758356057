@import "styles/_variables";

.video-container {
  width: 100%;

  iframe {
    width: 100%;
    margin: 0 auto;
    aspect-ratio: 16 / 9;
  }

  .info {
    padding-top: 1rem;

    @media (min-width: $breakpoint-tablet) {
      padding-top: 1.5rem;
    }
  }
}
