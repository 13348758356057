@import "styles/_mixin";
@import "styles/_variables";

.home-container {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 0 2rem;
  min-height: 100vh;
  @include background-image-blue;

  & .content {
    padding-top: 6rem;
  }
}

.opening {
  padding-top: 24px;
  padding-bottom: 62px;
  position: relative;

  .container {
    position: relative;
    width: auto;
    padding: 0;
  }

  & .logo {
    display: flex;
    justify-content: center;
    img {
      width: 20rem;
      height: 4rem;
    }
  }

  & .subtitle {
    font-family: var(--elliot-light);
    text-align: center;
    font-weight: 300;
    opacity: 0.69;
    margin-bottom: 32px;
    padding-top: 2rem;
  }

  & .search-bar-container {
    background: #6a798a;
    width: auto;
    height: 48px;
    margin-bottom: 84px;
    .search-bar {
      color: #fff;
    }
    .search-icon {
      img {
        filter: var(--filter-white);
      }
    }
  }

  & .inspired {
    text-align: center;
    font-family: var(--elliot-pro);
    font-size: 12px;
    opacity: 0.69;
    margin-bottom: 24px;
  }

  & .ask-button {
    position: absolute;
    z-index: 100;
    right: 28px;
    bottom: 0;
    transform: translateY(50%);
    width: 60px;
    height: 60px;
    background: #0065f2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    & .ask-icon {
      width: 36px;
      height: 36px;
      background: var(--white);
      border-radius: 50%;
    }
  }
}
.events {
  .container {
    position: relative;
    width: auto;
  }
}

/* Desktop Min Width 900 */

@media (min-width: $breakpoint-tablet) {
  .home-container {
    padding: 0 4rem;
    & .background {
      height: 100%;
    }
    & .content {
      padding-top: 10rem;
    }
  }
  .events {
    .container {
      width: calc(100% - 128px);
      margin: 0 auto;
      position: relative;
    }
  }
  .opening {
    padding-bottom: 88px;

    & .container {
      position: relative;
      width: calc(100% - 128px);
      margin: 0 auto;
    }

    & .logo img {
      min-width: 400px;
    }

    & .subtitle {
      font-size: 24px;
      margin-bottom: 64px;
    }

    & .search-bar-container {
      width: 760px;
      height: 64px;
      margin: 0 auto 131px;
      .search-bar {
        font-size: 18px;
        line-height: 2;
      }
      .search-icon {
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        img {
          width: 22px;
          height: 22px;
        }
      }
    }

    & .inspired {
      font-size: 16px;
      margin-bottom: 32px;
    }

    & .inspirations {
      width: 760px;
      margin: 0 auto;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
    }

    & .inspirations a {
      width: 236px;
      padding: 19px 0;
      margin-left: 8px;
      margin-right: 8px;
      font-size: 14px;
      letter-spacing: 1.17px;
    }

    & .inspirations a:last-child {
      margin-bottom: 24px;
    }

    & .ask-button {
      bottom: 219px;
      right: 16px;
    }
  }
}
