@import "styles/_variables";

.container-how-to {
  .downloads {
    .buttons-section:first-child {
      padding-bottom: 3.5rem;
    }
  }

  .myracle-videos {
    width: 100%;
    gap: 2rem;
    display: flex;
    flex-direction: column;
  }

  .contact-cards {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }

  .container-how-to__bottom-stickers {
    display: flex;
    justify-content: center;
    column-gap: 3rem;
    padding-top: 0.25rem;
    padding-bottom: 1rem;
    img {
      object-fit: cover;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    .downloads {
      .buttons-section:first-child {
        padding-bottom: 5.5rem;
      }
    }

    .myracle-videos {
      flex-direction: row;
    }

    .contact-cards {
      flex-direction: row;
      row-gap: 0;
      column-gap: 2rem;
      justify-content: space-between;
      .contact-card-container {
        width: calc(50% - 16px);
      }
    }

    .container-how-to__bottom-stickers {
      display: none;
    }
  }
}

